import React from 'react';

import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';
import CarouselPagination from '../Carousel/CarouselPagination';

import "./Banner.scss"
export default class AboutsBanner extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      tabIndex: null
    }
  }
  

  changeNavStyle = (arg) => {
    if (arg === 'fixed') {
      this.navList.className = 'nav-list-box fixed'
    } else {
      this.navList.className = 'nav-list-box'
    }

  }

  changePageSpeakHandle =(navIndex)=>{
    this.swiper && this.swiper.slideToLoop(navIndex);
  }
  

  componentDidMount() {
    if(typeof this.props.img ==  'object'){
      let that=this;
      this.swiper=new Swiper('.swiper-container-banner',{
        direction: 'horizontal',
        loop:true,
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        },
        effect : '',
        on:{
          slideChange(){
            that.props.changeBanner && that.props.changeBanner(this.realIndex);
            that.setState({
              tabIndex:this.realIndex
            })
            //this.props.onChange(this.realIndex);
          }
        }
        // navigation: {
        //   nextEl: '.swiper-button-next',
        //   prevEl: '.swiper-button-prev',
        // },
      })

      setInterval(e=>{
        this.swiper && this.swiper.slideNext();
      },5000)
    }
  }

  render() {
    const { tabIndex } = this.state
    const {img,height} = this.props;
    return (
      <div style={{height:height+'px'}} className="banner">
        {typeof img == 'string' && img && <img style={{height:height+'px'}} src={img}/>}
        {typeof img == 'object' && 
          <>
          <div className="swiper-container swiper-container-banner" style={{zIndex:0}}>
            <div className="banner-images swiper-wrapper" style={{height:height+'px',zIndex:0}}>
              {
                img.map((src,index)=><div key={index}  className="anime swiper-slide"><img src={src} style={{height:height+'px'}}/></div>)
              }
            </div>
          </div>
          <div className="banner-pagination">
            <CarouselPagination style='white' onChange={index=>this.changePageSpeakHandle(index)} length={img.length} index={tabIndex}></CarouselPagination>
          </div>
          </>

        }
        <img className="only-wap wap-banner" src="https://r.5rs.me/image/necis_wap/homePage.png?_=3780"/>
      </div>
    )
  }
}
