
import React,{Component} from 'react';
import {navigate} from 'gatsby';
import ScreenJs from '@/utils/screen';
import Utils from '@/utils/utils';

import './Header.scss';

export default class Header extends Component{
  constructor(props){
    super(props);
    this.state={
      adviser:'',
      token:'',
      hoverIndex: -1
    };

  }

  changeUser=()=>{
    const adviser=Utils.getCookies('adviser');
    const adviserToken=Utils.getCookies('match_token');
    this.setState({
      token:adviserToken,
      adviser
    })
    
  }

  navigatorTo=(url,top)=>{
    navigate(url);
  }

  loginOut(){
    this.setState({
      token:'',
      adviser:''
    })

    Utils.clearCookies('match_token');
    Utils.clearCookies('adviser');
    Utils.clearCookies('adviser_phone');

    // window.location.href='/register/';
    window.location.replace('/register/')

    // if(window.location.pathname='register'){
    //   //navigate('?type=login');
    //   window.location.href='/register';
    // }
    
  }

  navigateTo(url){

  }

  componentDidMount(){
    ScreenJs();
    window.addEventListener('resize',e=>{
      ScreenJs();
    })
    this.changeUser();
    this.getHoverIndex()
  }

  getHoverIndex() {
    let hoverIndex = -1
    NAVIGATOR.map((item,index)=>{
      if(item.url === '/') {
        if(window.location.pathname === '/') {
          hoverIndex = index
        }
      } else {
        if(window.location.pathname && window.location.pathname.indexOf(item.url)==0){
          hoverIndex = index
        }
      }
    })
    this.setState({
      hoverIndex
    })
  }


  render(){

    const {hoverIndex,token,adviser} = this.state;
    return <div className="header" >
      <div className="header-main">
        <div className="logo">
          <img src={require('../../assets/images/logo.png').default}/>
        </div>

        <div className="nav-list">
          <ul>
            {
              NAVIGATOR.map((item,index)=>{
                return <li key={index} onClick={e=>this.navigatorTo(item.url)} className={hoverIndex==index?'hover':''}>
                  <label>{item.label}</label>
                </li>
              })
            }
          </ul>
        </div>
        <div className="user-info">{adviser && token && <p title=""><label>{adviser}</label>&nbsp;<a onClick={e=>this.loginOut()}>注销</a> </p>}</div>
        <div className="btn">
          {(adviser && token)?<a href='/register/myEnroll'>我的报名</a>:<a href='/register/enroll'>立即报名</a>}
        </div>
      </div>
      
      

    </div>
  }
}

const NAVIGATOR=[
  {label:'首页',url:'/'},
  {label:'参赛指南',url:'/guide'},
  {label:'获奖作品',url:'/works'},
  {label:'联系我们',url:'/call'}
]