import React from 'react';
import './CarouselPagination.scss';

export default function CarouselPagination(props){
  let {swiper,index,length,style}=props;
  

  let changeHandle=(index)=>{
    props.onChange && props.onChange(index);
  }


  let list=[];
  for(let i=0;i<length;i++){
    list.push(<li key={i} onClick={e=>{changeHandle(i)}} className={i==index?'hover':''}></li>)
  }

  return (
    
    <ul className={"carousel-pagination"+(style?' carousel-pagination-'+style:'')}>{list}</ul>
  )
}