import React from 'react';
import './Footer.scss';

export default (props)=>{
  const {fixed}=props;

  return <footer className={"Footer"+(fixed?' footer-fixed':'')}>
    <p>
      <label><img src={require('../../assets/images/logo.png').default} alt="" /></label>
      <label><strong>大赛组委会秘书处办公室</strong></label>     
      <label>邮箱：necispr@duoyue.me </label>  
      <label>电话：15623088490（叶老师）</label>
    </p>

    <p>
      <label className='title'>联系地址</label>
      <label>湖北省武汉市洪山区关山大道43号泛悦城T2写字楼27层</label> 
      <label>武汉理工数字传播工程有限公司</label> 
      <label>邮政编码：430070</label>
    </p>

    <p>
      <label className='title'>版权所有</label>   
      <label>出版融合技术·编辑创新大赛（www.necis.me）</label>
      <label>Copyright© All rights reserved.</label>   
      <label><a target="_blank" href="https://beian.miit.gov.cn/">鄂ICP备15003054号-9</a></label>
    </p>
  </footer>
}